<template lang="pug">
EatView.bg-neutral-a
  template(v-slot:default)
    //-- teleport(to="#topBarAction")
      EatIconBtn.text-primary(icon="$camera")
    div.flex-grow-1
      div.profile-image-area.pt-8.pb-4.d-flex.justify-center
        EatImage.profile-image.rounded-circle.bg-white.flex-shrink-0(:src="image")
      
      RoundedContainer(v-if="settings.length" :horizontal-padding="false")
        template(v-for="setting in settings")
          EatLi.profile-info__list-item.d-flex.align-center(@click="goToEditPage(setting.location)")
            div.align-self-start.me-auto
              div.text--secondary {{ $t(setting.title) }}
              div {{ setting.value }}
            div.d-flex.align-center(v-if="setting.location")
              EatIcon(icon="$chevronRight" :size="16")
</template>

<script setup lang="ts">
import { setAppTitle } from "@/store/appTitle";
import { useUser } from "@/store/user";
import { genderTranslations } from "@/utils/genderTranslations";
import { onMounted, type Ref, ref } from "vue";
import { useRouter } from "vue-router";
import { DateTime } from "luxon";
import { useI18n } from "vue-i18n";
import { useApp } from "@/store/app";
import { OptionalUserFields, OptionalUserFieldsValues } from "@/models/App";

const image = new URL('@/assets/images/placeholder_customer.jpg', import.meta.url).toString();

interface Setting {
  title: string;
  value: string;
  location?: { name: string, query: Record<string, string> };
}

const { user, refreshUser } = useUser();
const { app } = useApp();
const settings: Ref<Setting[]> = ref([]);
const router = useRouter();
const i18n = useI18n();
const genders = genderTranslations(i18n);

setAppTitle(i18n.t("user.profileSettings.appTitle").toString());

const shouldHideField = (field: OptionalUserFields) => {
  return app.value?.optionalUserFields ? app.value?.optionalUserFields[field] === OptionalUserFieldsValues.HIDDEN : false;
};

onMounted(async () => {
  user.value ? refreshUser() : await refreshUser();
  settings.value = [
    {
      title: "form.email.fieldName",
      value: user.value?.email || ""
    },
    {
      title: "form.name.fieldName",
      value: user.value?.name + " " + user.value?.surname,
      location: { name: "user.editProfile", query: { name: "true" } }
    },
    {
      title: "form.phone.fieldName",
      value: user.value?.phones?.length ? user.value.phones[0] : "",
      location: { name: "user.editProfile", query: { phone: "true" } }
    }
  ];

  if(!shouldHideField(OptionalUserFields.GENDER)) {
    settings.value.push({
      title: "form.gender.fieldName",
      value: genders.find(gender => gender.value == user.value?.gender)?.label || "",
      location: { name: "user.editProfile", query: { gender: "true" } }
    });
  }

  if(!shouldHideField(OptionalUserFields.BIRTH_DATE)) { 
    settings.value.push({
      title: "form.birthDate.fieldName",
      value: user.value?.birthDate
        ? DateTime.fromFormat(user.value.birthDate, "yyyy-MM-dd").toFormat("dd-MM-yyyy")
        : "",
      location: { name: "user.editProfile", query: { birthDate: "true" } }
    });
  }

});

const goToEditPage = (location: { name: string, query: Record<string, string> } | undefined) => {
  if (location) router.push(location);
};
</script>

<style lang="scss" scoped>
.profile-image-area {
  .profile-image {
    width: 96px;
    z-index: 2;
    border: 3px solid #fff;
  }
}

.profile-info__list-item {
  min-height: 73px;
}
.orders-container {
  margin-top: 64px;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  .order {
    cursor: pointer;
    min-height: 73px;
    &:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
  }
}
</style>
